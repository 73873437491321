import React from "react"
import styled from "@emotion/styled"
import { Mail, Linkedin } from "react-feather"

import Image from "../components/image"
import ImageOF from "../components/imageOF"

const Container = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1050px) {
    width: 35%;
    margin-top: 24px;
  }
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 108px;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 1050px) {
    margin-bottom: 0;
  }
`

const Name = styled.h1`
  color: #1f2933;
  font-size: 24px;
  font-weight: bold;
  line-height: 140%;
  margin: 0;
`

const LinksContainer = styled.div`
  display: flex;
  align-self: center;
  justify-self: center;
`

const SingleLinkContainer = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 8px;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;
  background-color: #f5f7fa;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.15);
  & :hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`
const DescriptionText = styled.span`
  line-height: 140%;
  color: #323f4b;
  @media screen and (min-width: 1050px) {
    font-size: 16px;
  }
`

const ImageContainer = styled.div`
  align-self: center;
  justify-self: center;
`

const OwnerCard = ({ name }) => {
  let iconProps
  iconProps = {
    size: "16",
    color: "#3E4C59",
  }

  if (name === "ceciliabenetti") {
    return (
      <Container>
        <ImageContainer>
          <Image />
        </ImageContainer>
        <CardHeader>
          <Name>
            Mariacecilia <br />
            Benetti
          </Name>
          <LinksContainer>
            <a
              href="mailto:ceciliabenetti@benettifilippini.studio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SingleLinkContainer>
                <Mail {...iconProps} />
              </SingleLinkContainer>
            </a>
            <a
              href="https://it.linkedin.com/in/cecilia-benetti-65412863/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SingleLinkContainer style={{ marginLeft: "16px" }}>
                <Linkedin {...iconProps} />
              </SingleLinkContainer>
            </a>
          </LinksContainer>
        </CardHeader>
        <div>
          <DescriptionText>
            Esperto contabile, Revisore Legale dei conti, consulente COFIP –
            fondatore dello Studio - Gestore della crisi da sovraindebitamento
            OCC dell'Odcec di Brescia - esperta in tematiche fiscali, contabili
            e gestione aziendale.
          </DescriptionText>
        </div>
      </Container>
    )
  } else {
    return (
      <Container>
        <ImageContainer>
          <ImageOF />
        </ImageContainer>
        <CardHeader>
          <Name>
            Ombretta
            <br />
            Filippini
          </Name>
          <LinksContainer>
            <a
              href="mailto:ombrettafilippini@benettifilippini.studio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SingleLinkContainer>
                <Mail {...iconProps} />
              </SingleLinkContainer>
            </a>
            <a
              href="https://www.linkedin.com/in/ombretta-filippini-68891733"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SingleLinkContainer style={{ marginLeft: "16px" }}>
                <Linkedin {...iconProps} />
              </SingleLinkContainer>
            </a>
          </LinksContainer>
        </CardHeader>
        <div>
          <DescriptionText>
            Dottore Commercialista, Revisore Legale dei conti, Consulente del
            Giudice, consulente finanziario COFIP – Mediatore - Gestore della
            crisi da sovraindebitamento iscritto OCC dell'Odcec di Brescia -
            fondatore dello Studio, esperta in tematiche giuridiche, societarie,
            fiscali e bancarie.
          </DescriptionText>
        </div>
      </Container>
    )
  }
}

export default OwnerCard
